
import { Component, Vue } from 'vue-property-decorator'
import Placeholder from '@/views/Placeholder.vue'
import sidebar from '@/store/modules/sidebar'
import TheMiniSidebar from './TheMiniSidebar.vue'
import { SidebarName } from '@/models/Sidebar'
import VehicleSidebar from './VehicleSidebar.vue'
import EditUserSidebar from './EditUserSidebar.vue'

@Component({
  components: {
    TheMiniSidebar,
  },
})
export default class TheSidebar extends Vue {
  sidebar = sidebar

  handleFabClick(): void {
    if (this.sidebar.isOpen) {
      this.sidebar.pop()
    } else if (this.sidebar.isClosed) {
      this.sidebar.push(sidebar._fabComponent)
    }
  }

  get showFAB(): boolean {
    return this.sidebar.showFAB
  }

  get sidebarHasFabContent(): boolean {
    return !!this.sidebar.content?.fab
  }

  get width(): number {
    return this.sidebar.width
  }

  get title(): string {
    return this.sidebar.content?.title
  }

  get component(): typeof Placeholder {
    return this.sidebar.content?.component
  }

  get props(): Record<string, unknown> {
    return this.sidebar.content?.props
  }

  get on(): Record<string, unknown> {
    return this.sidebar.content?.on
  }

  openSidebarByName(name: SidebarName, id: number): void {
    switch (name) {
      case SidebarName.VEHICLE:
        sidebar.openSidebar({
          component: VehicleSidebar,
          width: 672,
          props: {
            activeVehicleDetailId: id,
            isModeEdit: true,
            title: '',
          },
          persist: true,
        })
        break
      case SidebarName.DRIVER:
        sidebar.openSidebar({
          component: EditUserSidebar,
          props: {
            user: null,
            userId: id,
            driverOnly: true,
          },
          width: 675,
        })
        break
    }
  }

  mounted(): void {
    const { sidebarName, sidebarId } = this.$route.query
    if (sidebarName && sidebarId) {
      this.openSidebarByName(
        sidebarName as SidebarName,
        parseInt(sidebarId as string)
      )
    }
  }

  activeComponentHasUrlQuery(): boolean {
    const { sidebarName, sidebarId } = this.$route.query
    return (
      sidebarName &&
      sidebarId &&
      this.sidebar.components[this.sidebar.components.length - 1].component ===
        (sidebarName === SidebarName.DRIVER ? EditUserSidebar : VehicleSidebar)
    )
  }

  beforeDestroy(): void {
    const { sidebarName, sidebarId } = this.$route.query
    if (this.activeComponentHasUrlQuery()) {
      this.$router.replace({ query: {} })
    }
  }
}
