
import { Component, Vue, Watch } from 'vue-property-decorator'
import BusifyLogo from '@/components/BusifyLogo.vue'
import { getNavigationLinks } from '@/data/navigation'
import { NavigationLink } from '@/models/NavigationLink'
import modules from '@/store/modules'
import auth from '@/store/modules/auth'
import impersonation from '@/store/modules/impersonation'
import { getId } from '@/utils/id'
@Component({
  components: {
    BusifyLogo,
  },
})
export default class TheMenuSidebar extends Vue {
  showSidebar = false
  sidebar = modules.menuSidebar
  impersonation = impersonation
  getId = getId
  navigation = getNavigationLinks()
  auth = auth

  get navigationItems(): NavigationLink[] {
    return this.navigation.filter((n) => !n.hidden)
  }
  get isSidebarOpen(): boolean {
    return this.sidebar.isOpen
  }

  get userName(): string {
    return auth.getFullName
  }

  @Watch('isSidebarOpen')
  isSidebarOpenChanged(value: boolean): void {
    if (value !== this.showSidebar) {
      this.showSidebar = value
    }
  }

  @Watch('showSidebar')
  showSidebarChanged(value: boolean): void {
    if (value !== this.sidebar.isOpen) {
      if (value) {
        this.sidebar.open()
      } else {
        this.sidebar.close()
      }
    }
  }

  isNavSelected(link: NavigationLink): boolean {
    return link && this.$route.name === link.name
  }

  handleNavigationClick(item: NavigationLink): void {
    if (item.name) {
      this.$router.push({ name: item.name })
    } else if (item.action) {
      item.action()
    } else if (item.href) {
      document.location.href = item.href
    }
  }

  mounted(): void {
    this.navigation = getNavigationLinks(auth.isBusifyPayProcessor)
  }
}
