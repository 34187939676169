var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.sidebar.overlayBackground && _vm.sidebar.state === 'open')?_c('v-overlay'):_vm._e(),_c('v-navigation-drawer',{key:"the-sidebar",staticClass:"overflow-visible z-100 sidebar-custom-style",attrs:{"id":"the-sidebar","absolute":"","floating":"","hide-overlay":"","stateless":"","right":"","value":_vm.sidebar.isOpen || _vm.sidebar.fabComponent !== null,"width":_vm.width}},[(_vm.showFAB)?_c('v-btn',{staticClass:"top-6 hover:text-primary",style:({
        left: '-22px',
        'box-shadow': '0 10px 20px 0 rgba(0, 0, 0, 0.1)',
      }),attrs:{"id":"sidebar-fab-button","fab":"","top":"","left":"","absolute":"","width":"45","height":"45","color":"white"},on:{"click":_vm.handleFabClick}},[(_vm.sidebar.isClosed)?_c('CUIcon',{key:"sidebar_left_arrow",attrs:{"id":"sidebar_left_arrow","aria-label":"Expand","color":"gray-text-dark"}},[_vm._v(" keyboard_arrow_left ")]):_c('CUIcon',{key:"sidebar_right_arrow",attrs:{"id":"sidebar_right_arrow","aria-label":"Hide","color":"gray-text-dark"}},[_vm._v(" keyboard_arrow_right ")])],1):_vm._e(),(_vm.title)?_c('header',{staticClass:"background-transparent",class:{
        'padding-t-12': _vm.sidebarHasFabContent,
        'padding-t-5': !_vm.sidebarHasFabContent,
        'padding-x-5': !_vm.sidebar.content.wide,
        'padding-x-8': _vm.sidebar.content.wide,
      },attrs:{"id":"sidebar-title"}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('span',{staticClass:"font-bold font-20"},[_vm._v(_vm._s(_vm.title))]),(!_vm.sidebar.content.fab)?_c('v-btn',{staticClass:"flex-shrink-1",attrs:{"id":"sidebar-close-button","icon":"","x-small":""}},[_c('CUIcon',{attrs:{"color":"bg-gray-6"},on:{"click":function($event){return _vm.sidebar.pop()}}},[_vm._v("close")])],1):_vm._e()],1)]):_vm._e(),_c('section',{class:{
        'padding-t-12': !_vm.title && _vm.sidebarHasFabContent,
      }},[_vm._l((_vm.sidebar.components),function(component,componentIndex){return [(
            componentIndex === _vm.sidebar.components.length - 1 ||
            component.persist
          )?_c(component.component,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(componentIndex === _vm.sidebar.components.length - 1),expression:"componentIndex === sidebar.components.length - 1"}],key:`${componentIndex}-${component.width}`,tag:"component"},'component',component.props,false),component.on)):_vm._e()]})],2)],1),_c('TheMiniSidebar')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }