
import { Component, Inject, Vue, Watch } from 'vue-property-decorator'
import TheMenuSidebar from '@/components/TheMenuSidebar.vue'
import TheAppBar from '@/components/TheAppBar.vue'
import modules from '@/store/modules'
import TheSidebar from '@/components/TheSidebar.vue'
import impersonation from '@/store/modules/impersonation'
import TheImpersonationModal from '@/components/TheImpersonationModal.vue'
import ChangePassword from '@/components/ChangePassword.vue'
import auth from '@/store/modules/auth'
import UpdateBusifyModal from '@/components/UpdateBusifyModal.vue'

@Component({
  components: {
    TheSidebar,
    TheMenuSidebar,
    TheAppBar,
    TheImpersonationModal,
    ChangePassword,
    UpdateBusifyModal,
  },
})
export default class Home extends Vue {
  @Inject({ default: { showUpdateUI: false } }) appOptions: Record<
    string,
    boolean
  >

  @Watch('$vuetify.breakpoint.mdAndUp')
  shouldHideSidebar(value: boolean): void {
    if (value) {
      modules.menuSidebar.close()
    }
  }
  impersonation = impersonation
  auth = auth
}
