
import { Component, Prop, Vue } from 'vue-property-decorator'
import client from '@/services/company'
import { filter } from '@/utils/filter'
import { Company } from '@/models/dto/Company'

@Component({})
export default class AutocompleteCompany extends Vue {
  @Prop({ required: false }) readonly value: Company | number
  @Prop({ type: Boolean, default: false }) readonly idOnly!: boolean
  companies: Company[] = []
  debounce: ReturnType<typeof setTimeout> | null = null
  query: string = null

  filter(company: Company, queryText = ''): boolean {
    if (!company) {
      this.query = null
      return false
    }
    this.query = queryText.toLowerCase()
    const matchingName = company.name?.toLowerCase()?.includes(this.query)
    const matchingEmail = company.email?.toLowerCase()?.includes(this.query)
    const matchingPhone = company.phone?.toLowerCase()?.includes(this.query)
    return matchingName || matchingEmail || matchingPhone
  }

  handleChangeInput($event: Company): void {
    this.query = $event?.name
    this.$emit('input', $event)
  }

  fetch(query = ''): void {
    if (this.debounce) {
      clearTimeout(this.debounce)
    }
    this.debounce = setTimeout(() => {
      const filters = filter()
      const parentFilter = filters.createParent('or')
      filters.add(parentFilter, {
        value: query,
        column: {
          _t_id: 'B2A2AE2A-5296-4C37-905D-2449828958CF',
          value: 'name',
          filterAsIs: true,
          filterType: 'contains',
        },
      })
      filters.add(parentFilter, {
        value: query,
        column: {
          _t_id: 'D6243861-0402-4CAA-B22C-395E921AA474',
          value: 'email',
          filterAsIs: true,
          filterType: 'contains',
        },
      })
      filters.add(parentFilter, {
        value: query,
        column: {
          _t_id: 'A3E667A8-624E-476C-AE8C-077A2AD4C791',
          value: 'phone',
          filterAsIs: true,
          filterType: 'contains',
        },
      })
      client
        .tableView({ filters: filters.asQueryParams() })
        .then((res) => res.data.resultList)
        .then((companies) => (this.companies = companies))
    }, 250)
  }

  companyName(company: Company): string {
    return company.name || ''
  }

  companyEmail(company: Company): string {
    return company.email || ''
  }

  companyPhone(company: Company): string {
    return company.phone || ''
  }

  get placeholderText(): string {
    return this.$attrs.placeholder || 'Search for name, email, or phone number'
  }
}
