
import { EventBus } from '@/utils/eventBus'
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class UpdateBusifyModal extends Vue {
  update(): void {
    EventBus.$emit('app:force-refresh')
  }
}
