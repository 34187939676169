
import impersonation from '@/store/modules/impersonation'
import { Component, Vue, Model } from 'vue-property-decorator'
import AutocompleteCompany from '@/components/AutocompleteCompany.vue'
import { Company } from '@/models/dto/Company'
import transformationSession from '@/services/transformation'
import auth from '@/store/modules/auth'
import localCache from '@/store/modules/localCache'

@Component({
  components: {
    AutocompleteCompany,
  },
})
export default class TheImpersonationModal extends Vue {
  @Model('input') readonly value!: boolean | null

  impersonation = impersonation
  company: Company | null = null
  search = ''

  setCompany(company: Company): void {
    this.company = company
  }

  async login(): Promise<void> {
    if (this.company !== null) {
      await transformationSession.start({ payload: this.company })
      await this.impersonation.fetchHasTransformationSession()
      localCache.clearCache()
      auth.refreshUser()
      window.location.reload()
    }
  }

  handleNewInput(isOpen: boolean): void {
    this.$emit('input', isOpen)
  }

  close(): void {
    this.impersonation.close()
    this.company = null
    this.search = ''
  }
}
