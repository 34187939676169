
import { Component, Vue } from 'vue-property-decorator'
import Placeholder from '@/views/Placeholder.vue'
import miniSidebar from '@/store/modules/miniSidebar'

@Component({})
export default class TheMiniSidebar extends Vue {
  miniSidebar = miniSidebar

  get isOpen(): boolean {
    return this.miniSidebar.isOpen
  }

  get width(): number {
    return this.miniSidebar.width
  }

  get height(): number {
    return this.miniSidebar.height
  }

  get offset(): string {
    return `${this.miniSidebar.offset}px`
  }

  get title(): string {
    return this.miniSidebar.content?.title
  }

  get component(): typeof Placeholder {
    return this.miniSidebar.content?.component
  }

  get props(): Record<string, unknown> {
    return this.miniSidebar.content?.props
  }

  get on(): Record<string, unknown> {
    return this.miniSidebar.content?.on
  }
}
